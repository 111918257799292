<mat-form-field appearance="outline" class="no-bottom">
  @if (label(); as label) {
    <mat-label>{{label}}</mat-label>
  }
  <input #inputRef matInput type="text"
         [matAutocomplete]="auto"
         [formControl]="control"
         [placeholder]="placeHolder()"
         [disabled]="disabled"
         (input)="getEntitiesFn(control.value)"
         (keydown.enter)="control.markAsTouched()"
         (focusout)="onTouched()"
  >
  @if (createNewSuffix()) {
    <span matSuffix class="creat-new-suffix">(Create New)</span>
  }
  <i matSuffix class="al-icon sm-md search-icon"
     [class]="control.value ? 'al-ico-dialog-x pointer' : 'al-ico-search'"
     (click)="control.value && control.setValue(null); inputRef.focus()"
     smClickStopPropagation
  ></i>
  <mat-autocomplete #auto="matAutocomplete"
                    class="light-theme"
                    classList="light-theme"
                    [displayWith]="displayFn"
                    (opened)="(!data().length || control.value) && getEntitiesFn('')"
                    autoActiveFirstOption>
    @if (createNewSuffix()) {
      <mat-option class="item" [value]="control.value" (onSelectionChange)="createNewSelected.emit($event.source.value)">
        "{{ control.value }}" <span class="new">(Create New)</span>
      </mat-option>
    }
    @for (entity of data() ; track entity.id) {
      <mat-option [value]="entity.name">
        <div [smSearchText]="control.value">{{ entity.name }}</div>
      </mat-option>
    }

    @if (data() === null) {
      <div class="py-4 pe-none">
        <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
      </div>
    }
    <sm-dots-load-more [class.hidden]="data() === null || noMoreOptions" [loading]="loading()" (loadMore)="loadMoreEntities(control.value)"></sm-dots-load-more>
    <mat-option disabled style="height: 0; min-height: 0;"></mat-option> <!-- Empty mat-option, so the autocomplete menu will always pop -->
  </mat-autocomplete>
</mat-form-field>
